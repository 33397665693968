import isServer from '../isServer';

function track(event, data) {
    try {
        data.event = event;
        if(!isServer()) {
            window.dataLayer.push(data);
        }
    } catch (e) { }
}

function TrackForGA4(data, clearEcommerce) {
  try {
    if (clearEcommerce) {
      window.dataLayer.push({ ecommerce: null });
    }
    window.dataLayer.push(data);
  } catch (error) {
    console.log(error);
  }
}

function trackEvent(event, category, action, label, eventData) {
    try {
        eventData = eventData || {};

        eventData.event = event;

        eventData.eventCategory = category;

        eventData.eventAction = action || 'Generico';

        if (label) {
            eventData.eventLabel = label;
        }

        if(!isServer()) {
            window.dataLayer.push(eventData);
            delete window.eventActionTracking; 
        }

    } catch (e) {}
}

function trackEcommerce(evento, ecommerceData, additionalData) {
    try {
        var data = {
            event: evento,
            ecommerce: ecommerceData
        };

        if (additionalData) {
            Object.assign(data, additionalData);
        }

        if(!isServer()) {
            window.dataLayer.push(data);
        }

    } catch (e) { }
}

export default {
    track,
    trackEvent,
    trackEcommerce,
    TrackForGA4
}